<template>
<div class="container-fliud">
 <Header></Header>
 
<div class="faqInfo">
   <h4 class="faq" style="color:#97c149"> {{trainings.country}}</h4>
<div class="gray">
  <div class="container">
     <div class="row"  >
        <div class="col-md-8">
          <h4>{{trainings.title}}</h4>
          <div class="row" style="margin-top:10px">
                <div class="col-md-12">
                    <img :src="imageSrc+trainings.imageFilesUploadId" alt=""  style="height:600px; width: 700px;" id="img">
                </div>
        </div>
          <p style="color:#707070">{{trainings.description}}</p>
        </div>
          <div class="col-md-4" style="border:1px solid #E5E5E5; border-radius: 10px; padding:30px">
            <div class="row">
              <div class="col-md-6" style="color:#707070">Trainers</div>
              <div class="col-md-6" style=" color: #98C149;">{{trainings.trainer}}</div>
            </div>
              <div class="row" style="margin-top:20px; margin-bottom:20px">
              <div class="col-md-6" style="color:#707070">Venue</div>
              <div class="col-md-6" style=" color: #98C149;">{{trainings.venue}}</div>
            </div>
              <div class="row" style="margin-top:20px; margin-bottom:20px">
                <div class="col-md-6" style="color:#707070">Location</div>
                <div class="col-md-6" style=" color: #98C149;"><a :href="mapOption" target="_blank" style="color:#98c149">View map</a></div>
                
            </div>
            <div class="row" style="margin-top:20px; margin-bottom:20px">
                <div class="col-md-6" style="color:#707070">Male Attendees</div>
                <div class="col-md-6" style=" color: #98C149;">{{trainings.maleAttendees}}</div>
            </div>
            <div class="row" style="margin-top:20px; margin-bottom:20px">
                <div class="col-md-6" style="color:#707070">No Of Trainings Conducted </div>
                <div class="col-md-6" style=" color: #98C149;">{{trainings.noOfTrainingsConducted}}</div>
            </div>
            <!-- <div class="row" style="margin-top:20px; margin-bottom:20px">
                <div class="col-md-6" style="color:#707070">Participants Upload Id</div>
                <div class="col-md-6" style=" color: #98C149;">{{trainings.participantsUploadId}}</div>
            </div> -->
            <!-- <div class="row" style="margin-top:20px; margin-bottom:20px">
                <div class="col-md-6" style="color:#707070">Image Files Upload Id</div>
                <div class="col-md-6" style=" color: #98C149;">{{trainings.imageFilesUploadId}}</div>
            </div> -->
            <div class="row">
              <div class="col-md-6" style="color:#707070">Training Date</div>
              <div class="col-md-6" style=" color: #98C149;">{{date}}</div>
            </div>
            <div class="row">
              <div class="col-md-6" style="color:#707070">End Date</div>
              <div class="col-md-6" style=" color: #98C149;">{{endData}}</div>
            </div>
          
          
          </div>
     </div>
  </div>
</div>
</div>
<Footer></Footer>

</div>
</template>

<script>
import moment from 'moment'
import Header from '../components/Navbar';
import Footer from '../components/footer'
import baseUrl from '../baseUrl'


export default {
   components: {
    Header,
   Footer
  },
    data() {
        return {
            id: this.$route.params.id,
           trainings:{},
          mapOption:{},
           country:{},
           date:{},
           endData:{}
        }
    },
 
    created() {
         baseUrl.get("/api/training/" + this.id)
            .then((res)=>{
            this.trainings = res.data
              const lat = res.data.latitude
              const long = res.data.longitude
              const googleMap =`https://www.google.com/maps?q=${lat},-${long}`
              this.mapOption = googleMap
              this.country = res.data.country
              const newDate = res.data.startDate
              this.date = moment(newDate).format('LL')
              this.endData = moment(res.data.endDate).format('LL')
            
            }).catch((err)=>{
            console.log(err)
            })
    },
  
}
</script>

<style scoped>
.faqInfo{
margin-top: 180px;
}
#accordion{
padding: 100px;
}
.faq{
padding-left: 100px;
}
/* .hr{
padding-left: 100px;
} */
.gray{
background-color: #F5F7FA;
padding: 60px;
}
.container{
background-color: rbg(236, 235, 235);
}
#tweets{
padding-left: 100px;
padding-right: 100px;
padding-bottom: 20px;
margin-top: 40px;
}
.col-md-4{
border-radius: 10px;
position: relative;

}
small{
padding: 20px;
}
.twiter{
    position: absolute;
    bottom: 370px;
    right: 140px;
  }
.figure{
background-color: rgb(255, 255, 255);
border-radius: 10px;
}
.figure-caption{
padding: 20px;
}
.showMore{
text-align: center;
padding: 50px;
}
.showMore button{
border: 1px solid rgb(170, 165, 165);
padding: 10px;
widows: 200px;
border-radius: 10px;
color: rgb(170, 165, 165);
}
#img{
height: 60%;
width: 100%;
border-radius: 50px;
}
#vidoePrev{
border-radius: 10px;
}
#prev{
background-color: #fff;
border-radius: 10px;
}
.col-md-6{
padding-right: 50px;
}
</style>